@import "../../node_modules/bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    color:#000000;
    font-size:16px;
}
section {
    padding: 50px 0;
}

.bg-dark {
    background-color: #0A496C !important;
    color: #ffffff !important;
}
.bg-light {
    background-color: #F0F8FC !important;
}
.btn-primary {
    background-color: #0A496C;
    color: #ffffff !important;
    border-radius: 10px;
}

.btn-primary:hover {
    background-color: #0A496C !important;
}

footer {
    background-color:#202A33;
    padding: 40px 0;
}
footer a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
}
footer p {
    font-size: 16px;
    color:#ffffff;
}

.fixed-width-table {
    table-layout: fixed;
}

.fixed-width-table th,
.fixed-width-table td {
    overflow-wrap: break-word;
    word-wrap: break-word;
    max-width: 100%;
}

.url-column {
    width: 20%;
}

.enabled-column {
    width: 10%;
}

.status-column {
    width: 15%;
}

.checked-column {
    width: 20%;
}

.result-column {
    width: 25%;
}